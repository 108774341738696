.page {
  margin: 0;
  width: 100%;
  height: 100%;

  background-color: var(--main-bg-color);

  display: flex;
  flex-direction: column;
  justify-content: center;

  align-items: center;
}

.sectionLogin {
  max-width: 900px;
  max-height: 500px;
  height: 100%;
  width: 100%;
  display: flex;
  box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
}

.logo {
  background-color: var(--main-color);
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 2rem;
}

.logo img {
  width: 100%;
  height: 100%;
}

.sectionForm {
  background-color: var(--second-bg-color);
  width: 60%;
  padding: 5%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sectionForm span {
  margin-right: 5px;
}

.sectionForm span a {
  color: var(--main-color);
  font-weight: bold;
}

.sectionForm h1 {
  color: #333333;
  margin: 0;
  font-size: 1rem;
  font-weight: 900;
}

.sectionForm span.label {
  font-weight: bold;
  color: #333333;
}

.forgotPassword {
  font-weight: 400;
}

.forgotPassword span {
  font-weight: 900;
  color: var(--main-color);
  cursor: pointer;
}

.forgotPassword span:hover {
  color: var(--main-color-dark);
}

.alert {
  max-width: 900px;
  width: 100%;
}

.sectionForm button {
  width: 7rem;
  height: 2rem;
  background: var(--main-color);
  margin: 10px 0;
  font-size: 1rem;
}

.sectionForm button:disabled {
  cursor: not-allowed;
}

.formLogin {
  width: 100%;
}

.loginFooter {
  width: 100%;

  display: flex;
  justify-content: center;
}

.loginFooter img {
  width: 100%;
  max-width: 350px;
}

.error {
  background-color: #ffcbcb !important;
}

@media (max-width: 900px) {
  .sectionLogin {
    max-width: initial;
    max-height: initial;
    flex-direction: column;
    
  }

  .formLogin {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .logo {
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
  }

  .logo img {
    max-width: 350px;
  }

  .sectionForm {
    width: 100%;
    height: 100%;
  }
}
