.header {
  position: -webkit-sticky; /* Necessário para funcionar no Safari */
  position: sticky;
  z-index: 10; /*10 - para maior ordem*/
  top: 0;

  width: 100%;
  height: var(--header-height);

  background-color: var(--second-bg-color);

  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
}

.logoLeft {
  display: flex;
  flex-direction: column;

  justify-content: center;

  height: 100%;
  max-width: 12.5rem;
}

.logoLeft img {
  max-width: 11rem;
}

.logoCenter {
  max-width: 12.5rem;
  padding: 0.8rem;
  width: 200px;
}

.logoCenter img {
  width: 100%;
  height: 100%;
}

.version span {
  color: var(--border-color);
  cursor: pointer;
}

@media (max-width: 900px) {
  .header {
    justify-content: center;
  }
}

@media (orientation: landscape) {
  .header {
    position: initial;
  }
}